import { FC, lazy } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Loadable from 'components/loadable';
import { LandingRoutes } from 'components/routes';

const Landing = Loadable(lazy(() => import('views/landing')));
const RequestSuccessPage = Loadable(lazy(() => import('views/request-success')));

const Views: FC = () => (
	<HashRouter>
		<Routes>
			<Route path="/" element={<LandingRoutes />}>
				<Route path="/" element={<Landing />} />
				<Route path="/request-success" element={<RequestSuccessPage />} />
			</Route>
		</Routes>
	</HashRouter>
);
export default Views;
