import { FC, ReactNode } from 'react';
import { styled, BoxProps, Box } from '@mui/material';
import { HEADER_HEIGHT } from 'utils/constants';

const Wrapper: FC<BoxProps> = styled(Box as FC<BoxProps>)(() => ({
	wikdth: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	marginTop: `${HEADER_HEIGHT}px`,
	height: `calc(100vh - ${HEADER_HEIGHT}px)`,
	overflowY: 'scroll'
}));

interface LayoutProps {
    children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => (
	<Wrapper>{children}</Wrapper>
);

export default Layout;