import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Layout from 'components/layout';
import Header from '../header';

export const LandingRoutes: FC= () => (
	<>
		<Header />
		<Layout  >
			<Outlet />
		</Layout>
	</>
);

