/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const utilsSlice = createSlice({
	name: 'utils',
	initialState: {
		showCUIDrawer: false,
	},
	reducers: {
		toggleCUIDrawer: (state, action) => {
			state.showCUIDrawer = action.payload;
		}
	},
});

export const { toggleCUIDrawer } = utilsSlice.actions;
export default utilsSlice.reducer;
