import { AppBar as MuiAppBar, AppBarProps, styled, Toolbar } from '@mui/material';
import { FC, ReactNode } from 'react';
import { HEADER_HEIGHT } from 'utils/constants';

const headerHeight = HEADER_HEIGHT;

const AppBar: FC<AppBarProps> = styled(MuiAppBar as FC<AppBarProps>, {
	shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen',
})(({ theme }) => ({
	display: 'flex',
	backgroundColor: theme.palette.background.paper,
	boxShadow: 'none',
	padding: '0',
	border: `1px solid ${theme.palette.divider}`,
	zIndex: theme.zIndex.drawer + 1,
	width: '100%',
	height: `${headerHeight}px`,
}));

interface HeaderWrapperProps {
	children?: ReactNode;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({ children }) => (
	<AppBar position='fixed'>
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: '30px' }}>
			{children}
		</Toolbar>
	</AppBar>
);

HeaderWrapper.defaultProps = {
	children: '',
};

export default HeaderWrapper;
