import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Flex from 'components/flex';
import { useNavigate } from 'react-router-dom';
import { TxtHeader } from 'views/landing/components/animed-text/index.styled';
import HeaderWrapper from './index.styled';
import Logo from '../logo';

const Header: FC = () => {
	const navigate = useNavigate();

	const scrollToMiddleContent = (id: string): void => {
		const section = document.getElementById(id);
		if (section) {
			const yOffset = -60;
			const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	};
	return (
		<HeaderWrapper>
			<Box onClick={(): void => navigate('/')}>
				<Logo />
			</Box>
			<TxtHeader onClick={() => scrollToMiddleContent('module')}>
				Module
			</TxtHeader>
			<TxtHeader onClick={() => scrollToMiddleContent('despre')}>
				Despre
			</TxtHeader>
			<Flex gap={2}>
				<Typography>Welcome !</Typography>
				<Typography>Test !</Typography>
			</Flex>
		</HeaderWrapper>
	);
};

export default Header;
