import './App.css';
import { FC, Suspense, useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import Views from './views';
import useThemeToggle from './hooks/use-theme-toggle';
import theme from './utils/config/theme';
import ThemeContext from './utils/contexts/theme';

const App: FC = () => {
	const [themeMode, toggleThemeMode] = useThemeToggle();
	const themeContext = useMemo(() => ({ themeMode, toggleThemeMode }), [themeMode, toggleThemeMode]);

	return (
		<Suspense fallback='Loading...'>
			<div className='App'>
				<ThemeProvider theme={theme[themeMode]}>
					<ThemeContext.Provider value={themeContext}>
						<Views />
					</ThemeContext.Provider>
				</ThemeProvider>
			</div>
		</Suspense>
	);
};

export default App;
