import { Theme, ThemeOptions, createTheme } from '@mui/material';

const lightTheme = (common: Omit<ThemeOptions, 'palette'>): Theme =>
	createTheme({
		...common,
		palette: {
			mode: 'light',
			primary: {
			  main: '#043e7d',
			  light: '#0d53fc',
			  dark: '#1e3058',
			},
			secondary: {
			  main: '#3c539b',
			  light: '#4e6bc9',
			  dark: '#2a3a6c',
			},
			common: {
			  black: '#282828',
			  white: '#F6FAF8',
			},
			background: {
			  default: '#f9fafc',
			  paper: '#fafafa',
			},
			success: {
			  main: '#1fd655',
			},
			error: {
			  main: '#e3242b',
			},
		  },
	});

export default lightTheme;
